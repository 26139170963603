export default [
  {
    url: '/Visão Geral',
    name: 'Perfis',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Perfis',
    index: 1,
    child: [
      {
        url: '/dashboards/project-geral',
        name: 'Visão Geral',
        icon: 'mdi mdi mdi-file-check',
        i18n: 'Visão Geral',
        index: 1.1,
      },
      {
        url: '/dashboards/project-pessoal',
        name: 'Perfil Pessoal',
        icon: 'mdi mdi mdi-account',
        i18n: 'Perfil Pessoal',
        index: 1.2,
      },
      {
        url: '/dashboards/blank',
        name: 'Redes Sociais e Canais Digitais',
        icon: 'mdi mdi mdi-cloud-check',
        i18n: 'Redes Sociais e Canais Digitais',
        index: 1.3,
      },
      {
        url: '/dashboards/project-profissional',
        name: 'Perfil Profissional',
        icon: 'mdi mdi mdi-briefcase',
        i18n: 'Perfil Profissional',
        index: 1.4,
      },
      {
        url: '/dashboards/project-time',
        name: 'Relação com o Clube',
        icon: 'mdi mdi mdi-soccer',
        i18n: 'Relação com o Clube',
        index: 1.5,
      },
      {
        url: '/dashboards/project-rel-esporte',
        name: 'Relação com o Esporte',
        icon: 'mdi mdi mdi-run',
        i18n: 'Relação com o Esporte',
        index: 1.6,
      }
    ]
  },
  {
    url: '',
    name: 'Perfil de Consumo',
    icon: 'mdi mdi mdi-account',
    i18n: 'Perfil de Consumo',
    index: 2,
    child: [
      {
        url: '/dashboards/project-cons-habitacao',
        name: 'Educação/Habitação',
        icon: 'mdi mdi mdi-home',
        i18n: 'Educação/Habitação',
        index: 2.1,
      },
      {
        url: '/dashboards/project-cons-financeiro',
        name: 'Produtos Financeiros',
        icon: 'mdi mdi mdi-cash-multiple',
        i18n: 'Produtos Financeiros',
        index: 2.2,
      },
      {
        url: '/dashboards/project-cons-transporte',
        name: 'Transporte',
        icon: 'mdi mdi mdi-car',
        i18n: 'Transporte',
        index: 2.3,
      },
      {
        url: '/dashboards/project-cons-bensdigital',
        name: 'Consumos bens e digitais',
        icon: 'mdi mdi mdi-cart',
        i18n: 'Bens de Consumo e Digitais',
        index: 2.4,
      }
    ]
  },

  {
    url: '/Negocios',
    name: 'Negócios',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Negócios',
    index: 3,
    child: [
      {
        url: '/dashboards/blank',
        name: 'Ingressos',
        icon: 'mdi mdi mdi-file-check',
        i18n: 'Ingressos',
        index: 2.1,
      },
      {
        url: '/dashboards/blank',
        name: 'Sócios',
        icon: 'mdi mdi mdi-account',
        i18n: 'Sócios',
        index: 2.2,
      },
      {
        url: '/dashboards/blank',
        name: 'E-commerce',
        icon: 'mdi mdi mdi-account',
        i18n: 'E-commerce',
        index: 2.3,
      },
      {
        url: '/dashboards/blank',
        name: 'Loja Oficial',
        icon: 'mdi mdi mdi-account',
        i18n: 'Loja Oficial',
        index: 2.4,
      },
      {
        url: '/dashboards/blank',
        name: 'Fancents',
        icon: 'mdi mdi mdi-account',
        i18n: 'Fancents',
        index: 2.5,
      },
      {
        url: '/dashboards/blank',
        name: 'Fanpoints',
        icon: 'mdi mdi mdi-account',
        i18n: 'Fanpoints',
        index: 2.6,
      },
      {
        url: '/dashboards/blank',
        name: 'Streaming',
        icon: 'mdi mdi mdi-account',
        i18n: 'Streaming',
        index: 2.7,
      }
    ]
  }
]