<template>
  <vs-row>
    <vs-col
      vs-lg="12"
      vs-xs="12"
    >
      <span
        v-for="(path, index) in breadcrumb"
        :key="index"
        class="text-white"
      >
        <span v-if="index !== breadcrumb.length-1">
          {{ path }} <i class="fa fa-chevron-right mx-1" />
        </span>
        <span
          v-else-if="breadcrumb.length > 1"
          style="font-style: italic"
        >
          {{ path }}
        </span>
      </span>
      <h2 class="mt-4 mb-3 text-white">
        {{ breadcrumb[breadcrumb.length - 1] }}
      </h2>
    </vs-col>
    <!-- <vs-col vs-lg="6" vs-xs="12" class="d-none d-md-block">
      <vs-breadcrumb v-if="$route.meta.breadcrumb" align="right">
        <li>
          <router-link to="/dashboards/general-surveys">
            <vs-icon icon-pack="mdi mdi-home"></vs-icon>
          </router-link>
          <span class="vs-breadcrum--separator">/</span>
        </li>
        <li v-for="(bcrumb, index) in $route.meta.breadcrumb.slice(0,-1)" :key="index">
          <router-link :to="bcrumb.url" v-if="bcrumb.url">{{ bcrumb.title }}</router-link>
          <span class="text-primary cursor-default" v-else>{{ bcrumb.title }}</span>
          <span class="vs-breadcrum--separator">/</span>
        </li>
        <li class="inline-flex">
          <span
            v-if="$route.meta.breadcrumb.slice(-1)[0].active"
            class="cursor-default"
          >{{ $route.meta.breadcrumb.slice(-1)[0].title }}</span>
        </li>
      </vs-breadcrumb>
    </vs-col> -->
  </vs-row>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    breadcrumb() {
      let breadcrumb = []
      this.$store.state.menuSelectItem.forEach(el => {
        if (el.categoria) {
          breadcrumb.push(el.categoria)
        }
        else if (el.nomeSubCat) {
          breadcrumb.push(el.nomeSubCat)
        }
      })
      return breadcrumb
    }
  },
};
</script>
