<template>
  <div
    class="main-wrapper"
    :class="[mainWrapperClass]"
  >
    <!---Sidebar-->
    <SideBar
      parent=".main-wrapper"
      :sidebar-links="sidebarLinksFile()"
    />

    <!---Page Container-->
    <div
      class="main-container pb-5 pt-4 mb-4 primary"
      style="height:300px;"
      :style="{backgroundColor: headerColor}"
    />

    <div
      class="main-container-fluid py-2"
      style="margin-top: -300px; min-height: 100vh"
      :style="{backgroundColor: headerColor}"
    >
      <Breadcrumb />

      <transition
        name="router-anim"
        enter-active-class="fade-enter-active fade-enter"
        leave-active-class="fade-leave-active fade-enter"
      >
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import SideBar from '@/layout/full/surveysidebar/SideBar.vue';
import sidebarLinks from '@/layout/full/surveysidebar/boxsidebarlinks.js';
import sidebarLinksFutebol from '@/layout/full/surveysidebar/boxsidebarlinksFutebol.js';
import sidebarLinksCli from '@/layout/full/surveysidebar/boxsidebarlinksCli.js';
import sidebarLinksInter from '@/layout/full/surveysidebar/boxsidebarlinksInter.js';

import sidebarLinksFutebolCli from '@/layout/full/surveysidebar/boxsidebarlinksFutebolCli.js';
import Breadcrumb from '@/layout/full/breadcrumb/Breadcrumb.vue';

export default {
  name: 'MainContainer',
  components: {
    // Navbar,
    SideBar,
    Breadcrumb
  },
  computed: {
    headerColor() {
      return this.$store.state.configBox.cor1
    },
    sidebarWidth: function() {
      return this.$store.state.sidebarWidth;
    },
    mainWrapperClass: function() {
      if(this.sidebarWidth == 'default') {
        return 'main-wrapper-default'
      }
      else if(this.sidebarWidth == 'mini') {return 'main-wrapper-mini'}
      else if(this.sidebarWidth){ return 'main-wrapper-full'}
      return 'default'
    },

  },
  methods: {
    sidebarLinksFile() {
      if (this.$store.state.selectedProject == 2) {
        if (this.$store.state.tk == 'Fanbase') {
          return sidebarLinks;
        } else {
          return sidebarLinksCli;
        }
      } else {
        if (this.$store.state.tk == 'Fanbase') {
          return sidebarLinksFutebol;
        } else if (this.$store.state.selectedProject == 1) {
          return sidebarLinksInter;
        } else {
          return sidebarLinksFutebolCli;
        }
      }
    }
  },
}
</script>
