export default [
  {
    url: '/Visão Geral',
    name: 'Perfis',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Perfis',
    index: 1,
    child: [
      {
        url: '/dashboards/project-geral',
        name: 'Visão Geral',
        icon: 'mdi mdi mdi-file-check',
        i18n: 'Visão Geral',
        index: 1.1,
      },
      {
        url: '/dashboards/project-pessoal',
        name: 'Perfil Pessoal',
        icon: 'mdi mdi mdi-account',
        i18n: 'Perfil Pessoal',
        index: 1.2,
      },
      {
        url: '/dashboards/blank',
        name: 'Redes Sociais e Canais Digitais',
        icon: 'mdi mdi mdi-cloud-check',
        i18n: 'Redes Sociais e Canais Digitais',
        index: 1.3,
      },
      {
        url: '/dashboards/project-profissional',
        name: 'Perfil Profissional',
        icon: 'mdi mdi mdi-briefcase',
        i18n: 'Perfil Profissional',
        index: 1.4,
      },
      {
        url: '/dashboards/project-rel-basquete',
        name: 'Relação com o Basquete',
        icon: 'mdi mdi mdi-dribbble',
        i18n: 'Relação com o Basquete',
        index: 1.5,
      },
      {
        url: '/dashboards/project-rel-esporte',
        name: 'Relação com o Esporte',
        icon: 'mdi mdi mdi-run',
        i18n: 'Relação com o Esporte',
        index: 1.6,
      }
    ]
  },
  {
    url: '',
    name: 'Perfil de Consumo',
    icon: 'mdi mdi mdi-account',
    i18n: 'Perfil de Consumo',
    index: 2,
    child: [
      {
        url: '/dashboards/project-cons-habitacao',
        name: 'Educação/Habitação',
        icon: 'mdi mdi mdi-home',
        i18n: 'Educação/Habitação',
        index: 2.1,
      },
      {
        url: '/dashboards/project-cons-financeiro',
        name: 'Produtos Financeiros',
        icon: 'mdi mdi mdi-cash-multiple',
        i18n: 'Produtos Financeiros',
        index: 2.2,
      },
      {
        url: '/dashboards/project-cons-transporte',
        name: 'Transporte',
        icon: 'mdi mdi mdi-car',
        i18n: 'Transporte',
        index: 2.3,
      },
      {
        url: '/dashboards/project-cons-bensdigital',
        name: 'Bens de Consumo e Digitais',
        icon: 'mdi mdi mdi-cart',
        i18n: 'Bens de Consumo e Digitais',
        index: 2.4,
      }
    ]
  },

  {
    url: '/Negocios',
    name: 'Negócios',
    icon: 'mdi mdi-view-dashboard',
    i18n: 'Negócios',
    index: 3,
    child: [
      {
        url: '/dashboards/blank',
        name: 'Ingressos',
        icon: 'mdi mdi mdi-file-check',
        i18n: 'Ingressos',
        index: 2.1,
      },
      {
        url: '/dashboards/blank',
        name: 'Sócios',
        icon: 'mdi mdi mdi-account',
        i18n: 'Sócios',
        index: 2.2,
      },
      {
        url: '/dashboards/blank',
        name: 'E-commerce',
        icon: 'mdi mdi mdi-account',
        i18n: 'E-commerce',
        index: 2.3,
      },
      {
        url: '/dashboards/blank',
        name: 'Loja Oficial',
        icon: 'mdi mdi mdi-account',
        i18n: 'Loja Oficial',
        index: 2.4,
      },
      {
        url: '/dashboards/blank',
        name: 'Fancents',
        icon: 'mdi mdi mdi-account',
        i18n: 'Fancents',
        index: 2.5,
      },
      {
        url: '/dashboards/blank',
        name: 'Fanpoints',
        icon: 'mdi mdi mdi-account',
        i18n: 'Fanpoints',
        index: 2.6,
      },
      {
        url: '/dashboards/blank',
        name: 'Streaming',
        icon: 'mdi mdi mdi-account',
        i18n: 'Streaming',
        index: 2.7,
      }
    ]
  },
  {
    url: '/iddigital',
    name: 'ID Digital',
    icon: 'mdi  mdi-account-check',
    i18n: 'Config',
    index: 4,
    child: [
      {
        url: '/IDDigital/registros',
        name: 'Lista de Registros',
        icon: 'mdi mdi mdi-account-multiple',
        i18n: 'Registros',
        index: 1.1,
      },
      {
        url: '/IDDigital/filtros',
        name: 'Fitro de Registros',
        icon: 'mdi mdi mdi-filter',
        i18n: 'Campanhas',
        index: 1.1,
      }
      /* ,
            {
                url: '/dashboards/id-digital',
                name: "iddigital",
                icon: "mdi mdi mdi-adjust",
                i18n: "iddigital",
                index: 1.32,
            },
            {
                url: '/dashboards/classic-dashboard',
                name: "Classic",
                icon: "mdi mdi mdi-adjust",
                i18n: "Classic",
                index: 1.1,
            },
            {
                url: '/dashboards/analytical-dashboard',
                name: "Analytical",
                icon: "mdi mdi mdi-adjust",
                i18n: "Analytical",
                index: 1.2,
            },
            {
                url: '/dashboards/ecommerce-dashboard',
                name: "Ecommerce",
                icon: "mdi mdi mdi-adjust",
                i18n: "Ecommerce",
                index: 1.3,
            },
            {
                url: '/dashboards/general-dashboard',
                name: "General",
                icon: "mdi mdi mdi-adjust",
                i18n: "General",
                index: 1.4,
            }*/
    ]
  }
]